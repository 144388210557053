export const initState = {
  token: '',
  applications: {
    ids: [],
    entities: {},
  },
  qrcodes: {
    ids: [],
    entities: {},
  },
  appQrRelations: {
    ids: [],
    entities: {},
  },
};
