import React from 'react';
import { History } from 'history';
import { Footer } from 'components/footer';
import { IconBack } from 'icons';
import { Link } from 'react-router-dom';
import { getDeviceId } from './getDeviceId.js';
import { iOS } from 'shared/index.js';
interface Props {
  history: History;
}

export class Scan extends React.PureComponent<Props> {
  codeReader;

  handlerError = error => {
    if (error) {
      console.error(error);
    }
  };
  handlerReader = code => {
    if (code) {
      const { history } = this.props;
      history.push(`/applications?code=${code}`, {
        code,
      });
    }
  };

  initiate () {
    // const facingMode = ['user', 'environment'];
    const facingMode = 'environment';

    // Check browser facingMode constraint support
    // Firefox ignores facingMode or deviceId constraints
    let supported = {};
    if (navigator.mediaDevices && typeof navigator.mediaDevices.getSupportedConstraints === 'function') {
      supported = navigator.mediaDevices.getSupportedConstraints();
    }
    const constraints = {};

    if (supported.facingMode) {
      constraints.facingMode = { ideal: facingMode };
    }
    if (supported.frameRate) {
      constraints.frameRate = { ideal: 25, min: 10 };
    }

    return getDeviceId(facingMode);
  }

  async componentDidMount () {
    let selectedDeviceId;
    this.codeReader = new ZXing.BrowserQRCodeReader();
    this.codeReader
      .getVideoInputDevices()
      .then(async videoInputDevices => {
        try {
          if (!iOS()) {
            selectedDeviceId = await this.initiate();
          } else {
            selectedDeviceId = videoInputDevices[0].deviceId;
          }
        } catch (error) {
          selectedDeviceId = videoInputDevices[0].deviceId;
        }
        this.codeReader
          .decodeFromInputVideoDevice(selectedDeviceId, 'video')
          .then(({ text }) => {
            this.handlerReader(text);
          })
          .catch(err => {
            const { text } = err;
            this.handlerReader(text);
          });
      })
      .catch(err => {
        console.error(err);
      });
  }

  componentWillUnmount () {
    this.codeReader.reset();
  }
  render () {
    return (
      <>
        <div className="layout-body">
          <div className="section-scan">
            <div className="scan-video-block">
              <video id="video" width="100%" height="100%" className="video-tag" />
            </div>
          </div>
        </div>
        <Footer>
          <div className="panel-left">
            <Link to="/" className="link-back">
              <IconBack /> Back
            </Link>
          </div>
        </Footer>
      </>
    );
  }
}
