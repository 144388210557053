import React from 'react';

interface Props {
  children: React.ReactElement;
}

export class LayoutBody extends React.Component<Props> {
  render () {
    const { children } = this.props;
    return <div className="layout-body">{children}</div>;
  }
}
