import React from 'react';
import { IconScan } from 'icons';
import { Link } from 'react-router-dom';
import { Logo } from 'components/logo';
import { Footer } from 'components/footer';
import { LayoutBody } from 'components/layout';
import { Container } from 'components/container';
import { GridDefault } from 'components/layout/grid-default.component';
import { AppItem } from 'components/app-item/app-item.component';
import { History } from 'history';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { CONTAINER } from 'consts';
import { CircularProgress } from '@material-ui/core';
import isEmpty from 'lodash/isEmpty';
import { SegmentInformation } from 'components/segment-information';
import { getQRCodeByCode, getApplicationsByQRCodeName } from 'redux/selectors';

interface Props {
  history: History;
  fetchApplications: Function;
  applications: Array;
  code: String;
  qrCode: Object;
}

const styleLoader = {
  margin: '20px auto',
  display: 'block',
};

class List extends React.PureComponent<Props> {
  state = {
    isLoading: false,
  };

  goToLink = url => {
    if (url) {
      window.open(url, 'blank');
    }
  };
  render () {
    const { applications, qrCode } = this.props;
    const { isLoading } = this.state;

    const ApplicationBlock = () => {
      if (!isEmpty(applications)) {
        return applications.map(app => (
          <AppItem
            key={app.id}
            onClick={this.goToLink}
            url={app.url}
            title={app.name}
            backgroundImage={`url(${CONTAINER}/${app.image})`}
          />
        ));
      }
      return (
        <>
          <p className="text-message">There is no applications for this QR-code.</p>
          <p className="text-message">Please try another one.</p>
        </>
      );
    };

    return (
      <>
        <LayoutBody>
          <div className="section-list">
            <Container>
              <div className="section-list__head">
                <Logo/>
              </div>
              <div className="section-list__body">
                {
                  isLoading
                    ? (
                      <CircularProgress style={styleLoader}/>
                    )
                    : (
                      <>
                        <SegmentInformation text={qrCode.text}/>
                        <GridDefault>
                          <ApplicationBlock/>
                        </GridDefault>
                      </>
                    )
                }
              </div>
            </Container>
          </div>
        </LayoutBody>
        <Footer>
          <div className="panel-center">
            <Link to="/scanner" className="link-scan">
              Scan code <IconScan/>
            </Link>
          </div>
        </Footer>
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { history } = props;
  const { code } = queryString.parse(history.location.search);
  return {
    applications: getApplicationsByQRCodeName(state, code),
    code,
    qrCode: getQRCodeByCode(state, code),
  };
};

const mapDispatchToProps = null;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(List);
