import React from 'react';
import { Paper } from '@material-ui/core';
interface Props {
  title: String;
  onClick?: Function;
  backgroundImage: String;
  url: String;
}

export class AppItem extends React.PureComponent<Props> {
  _onClick = () => {
    const { url, onClick } = this.props;
    if (onClick) {
      onClick(url);
    }
  };
  render () {
    const { title, backgroundImage } = this.props;
    return (
      <div className="grid-default__item x4">
        <Paper className="app-panel">
          <div onClick={this._onClick} className="app-item">
            <div className="app-item__icon" style={{ backgroundImage }} />
            <div className="app-item__title">{title}</div>
          </div>
        </Paper>
      </div>
    );
  }
}
