import React from 'react';

interface Props {
  children: React.ReactElement;
}

export class GridDefault extends React.Component<Props> {
  render () {
    const { children } = this.props;
    return <div className="grid-default">{children}</div>;
  }
}
