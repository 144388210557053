import { Switch, Route } from 'react-router';
import React from 'react';
import { Scan } from 'scenes';
import PropTypes from 'prop-types';
import { DefaultLayout } from 'components/layout';

export const wrapperScan = ({ match }) => (
  <DefaultLayout>
    <Switch>
      <Route path={`${match.url}`} component={Scan} />
    </Switch>
  </DefaultLayout>
);

wrapperScan.propTypes = {
  match: PropTypes.object,
};
