import axios from 'axios';
import { API } from 'consts';

const getQrCodesAll = () => {
  return axios.get(`${API}/QRCode`, {});
};

export default {
  getQrCodesAll,
};
