import * as axios from 'axios';
import { API } from 'consts';

const getApplicationsByQRCodeId = qrCodeId => {
  return axios.get(`${API}/QRCode/${qrCodeId}/applications`);
};
const getApplications = () => {
  return axios.get(`${API}/Applications`);
};

export default {
  getApplicationsByQRCodeId,
  getApplications,
};
