import Api from 'api';
import { normalize } from 'normalizr';
import { appQrRelations as appQrRelationsSchemes } from 'schemes';
import { fetchAppQrRelations } from 'redux/actions';

export const fetchAppQrRelationsAll = () => async dispatch => {
  try {
    const { data } = await Api.getAppQrRelations();
    const {
      result,
      entities: { appQrRelations },
    } = normalize(data, appQrRelationsSchemes);

    return dispatch(fetchAppQrRelations(result, appQrRelations));
  } catch (error) {
    console.error(error);
    throw error;
  }
};
