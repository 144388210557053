import React from 'react';
import { Route, Router, Switch } from 'react-router';
import { createBrowserHistory } from 'history';
import { wrapperList } from './list/list.routes';
import { wrapperScan } from './scan/scan.routes';
import { wrapperHome } from './home/home.routes';
import connect from 'react-redux/es/connect/connect';
import { fetchApplicationsAll, fetchQrCodeByCode, fetchAppQrRelationsAll } from 'redux/thunks';

const history = createBrowserHistory({});

interface Props {
  fetchQrCodes: Function;
  fetchApplications: Function;
  fetchAppQrRelations: Function;
}

class DefaultRoute extends React.Component<Props> {
  componentWillMount () {
    const { fetchQrCodes, fetchApplications, fetchAppQrRelations } = this.props;

    Promise.all([
      fetchQrCodes(),
      fetchApplications(),
      fetchAppQrRelations(),
    ]);

  }
  render () {
    return (
      <Router history={history}>
        <Switch>
          <Route path="/applications" component={wrapperList} />
          <Route exec path="/scanner" component={wrapperScan} />
          <Route path="/" component={wrapperHome} />
        </Switch>
      </Router>
    );
  }
}
const mapStateToProps = null;

const mapDispatchToProps = dispatch => {
  return {
    fetchApplications: () => dispatch(fetchApplicationsAll()),
    fetchQrCodes: code => dispatch(fetchQrCodeByCode(code)),
    fetchAppQrRelations: () => dispatch(fetchAppQrRelationsAll()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DefaultRoute);
