import React from 'react';

export class IconBack extends React.PureComponent {
  render () {
    return (
      <svg
        viewBox="0 0 27 19"
        className="icon icon-back"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <path d="M26.6016547,10 L1.39834533,10 C1.17845871,10 1,9.776 1,9.5 C1,9.224 1.17845871,9 1.39834533,9 L26.6016547,9 C26.8215413,9 27,9.224 27,9.5 C27,9.776 26.8215413,10 26.6016547,10 Z" />
        <path d="M10.5935105,19 C10.4895012,19 10.3854919,18.9642888 10.3058598,18.8935952 L0.118635617,9.75589992 C0.0430663539,9.68739272 0,9.59483511 0,9.4979047 C0,9.40097429 0.0430663539,9.30841668 0.118635617,9.23990948 L10.3066723,0.10658701 C10.465124,-0.0355290034 10.7227096,-0.0355290034 10.8811612,0.10658701 C11.0396129,0.248703024 11.0396129,0.479732645 10.8811612,0.621848659 L0.980775268,9.4979047 L10.8811612,18.3776047 C11.0396129,18.5197208 11.0396129,18.7507504 10.8811612,18.8928664 C10.8015291,18.9642888 10.6975198,19 10.5935105,19 Z" />
      </svg>
    );
  }
}
