import { createSelector } from 'reselect';
import { createArraySelector } from 'shared/utils';

const _getQrCodes = state => createArraySelector('qrcodes')(state);

export const getQRCodeByCode = createSelector(
  [_getQrCodes, (state, selectedCode) => selectedCode],
  (qrCode, selectedCode) => qrCode.find(({code}) => code === selectedCode) || {}
);

