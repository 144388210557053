import { combineReducers } from 'redux';
import { authReducer } from './auth/auth.reducer';
import { applicationsReducer } from './applications/applications.reducer';
import { qrCodesReducer } from './qr-codes/qr-codes.reducer';
import { appQrRelationsReducer } from './app-qr-relations/app-qr-relations.reducer';

export default combineReducers({
  token: authReducer,
  applications: applicationsReducer,
  qrcodes: qrCodesReducer,
  appQrRelations: appQrRelationsReducer,
});
