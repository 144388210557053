import React from 'react';
import { Paper } from '@material-ui/core';

interface Props {
  text: String;
}

export class SegmentInformation extends React.PureComponent<Props> {
  render () {
    const { text } = this.props;

    if (!text) return null;

    return (
      <Paper className="details">
        <div className="qrcode-text">
          <p>
            {text}
          </p>
        </div>
      </Paper>
    );
  }
}
