import * as axios from 'axios';
import { API } from 'consts';

const getAppQrRelations = () => {
  return axios.get(`${API}/ApplicationsQRCode`);
};

export default {
  getAppQrRelations,
};
