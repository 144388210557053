import { createSelector } from 'reselect';
import { createArraySelector } from 'shared';
import { getQRCodeByCode } from '../qr-codes/qr-codes.selector';
import { getAppQrRelationsAll } from '../app-qr-relations/app-qr-relations.selector';
import isEmpty from 'lodash/isEmpty';

const _getApplications = state => createArraySelector('applications')(state);

export const getApplicationsByQRCodeName = createSelector(
  [_getApplications, getQRCodeByCode, getAppQrRelationsAll, (state, code) => code],
  (applications, qrCode, appQrRel) => {
    const appQrRelationsFiltered = appQrRel.filter(({qrcodeId}) => qrcodeId === qrCode.id);
    return applications.filter(application => {
      const appFindedRelaions = appQrRelationsFiltered.find((item) => {
        return application.id === item.applicationId;
      });
      return !isEmpty(appFindedRelaions);
    });
  }
);

