import { Switch, Route } from 'react-router';
import React from 'react';
import { Home } from 'scenes';
import PropTypes from 'prop-types';
import { DefaultLayout } from 'components/layout';

export const wrapperHome = ({ match }) => (
  <DefaultLayout>
    <Switch>
      <Route path={`${match.url}`} component={Home} />
    </Switch>
  </DefaultLayout>
);

wrapperHome.propTypes = {
  match: PropTypes.object,
};
