import React from 'react';

export class Logo extends React.PureComponent {
  render () {
    return (
      <div className="module-company">
        <div className="module-company__logo" />
        <div className="module-company__slogan">digital</div>
      </div>
    );
  }
}
