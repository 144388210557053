import Api from 'api';
import { normalize } from 'normalizr';
import { applications as applicationsSchemes } from 'schemes';
import { fetchApplications } from 'redux/actions';

export const fetchApplicationsAll = () => async dispatch => {
  try {
    const { data } = await Api.getApplications();
    const {
      result,
      entities: { applications },
    } = normalize(data, applicationsSchemes);

    return dispatch(fetchApplications(result, applications));
  } catch (error) {
    console.error(error);
    throw error;
  }
};
