import React from 'react';

export class IconScan extends React.PureComponent {
  render () {
    return (
      <svg
        viewBox="0 0 19 19"
        className="icon icon-scan"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <path d="M1.425,1.425 L6.65,1.425 L6.65,0 L0,0 L0,6.65 L1.425,6.65 L1.425,1.425 Z M12.35,0 L12.35,1.425 L17.575,1.425 L17.575,6.65 L19,6.65 L19,0 L12.35,0 Z M1.425,12.35 L0,12.35 L0,19 L6.65,19 L6.65,17.575 L1.425,17.575 L1.425,12.35 Z M17.575,17.575 L12.35,17.575 L12.35,19 L19,19 L19,12.35 L17.575,12.35 L17.575,17.575 Z M2.85,9.975 L16.15,9.975 L16.15,9.025 L2.85,9.025 L2.85,9.975 Z" />
      </svg>
    );
  }
}
