import { createSelector } from 'reselect';
import { createArraySelector } from 'shared';

const _getAppQrRelations = state => createArraySelector('appQrRelations')(state);

export const getAppQrRelationsAll = createSelector(
  [_getAppQrRelations],
  (appQrRelations) => appQrRelations
);

