import applications from './applications/applications.api';
import qrCode from './qr-codes/qr-codes.api';
import appQrrelations from './app-qr-relations/app-qr-relations.api';

const Api = {
  ...appQrrelations,
  ...qrCode,
  ...applications,
};

export default Api;
