import React, { Component } from 'react';
import { Container } from '../container';

interface Props {
  children: React.ReactElement;
}

export class Footer extends Component<Props> {
  render () {
    const { children } = this.props;
    return (
      <div className="layout-footer">
        <Container>
          <div className="layout-footer__inner">{children}</div>
        </Container>
      </div>
    );
  }
}
