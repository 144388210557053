import React from 'react';
import { IconScan } from 'icons';
import { Link } from 'react-router-dom';
import { Logo } from 'components/logo';
import { Footer } from 'components/footer';
import { Container } from 'components/container';
import { LayoutBody } from 'components/layout';
export class Home extends React.PureComponent {
  render () {
    return (
      <>
        <LayoutBody>
          <div className="section-welcome">
            <Container>
              <Logo />
            </Container>
          </div>
        </LayoutBody>
        <Footer>
          <div className="panel-center">
            <Link to="/scanner" className="link-scan">
              Scan code <IconScan />
            </Link>
          </div>
        </Footer>
      </>
    );
  }
}
