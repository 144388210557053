import { compose } from 'redux';
import reducer from 'redux/reducers';
import thunk from 'redux-thunk';
import { batch } from 'shared';
// The relevant stuff
import getPersistMiddleware from 'redux-persist-middleware';
import { getConfiguredCache } from 'money-clip';
import ms from 'milliseconds';

const isDevelopment = process.env.NODE_ENV === 'development';
const composeEnhancers = isDevelopment ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : compose;

// Here we use the money-clip library to
// creates an object of cache functions with
// these options pre-applied
const cache = getConfiguredCache({
  version: 1,
  maxAge: ms.days(30),
});

// A mapping of actions to reducers we should
// persist after those actions occur
const actionMap = {
  FETCH_QR_CODE: ['qrcodes'],
  FETCH_APPLICATIONS_SUCCESS: ['applications'],
  FETCH_APP_QR_RELATIONS: ['appQrRelations'],
};

// Configure our middleware
const persistMiddleware = getPersistMiddleware({
  // a function to call to persist stuff.
  // This *must* return a Promise and
  // *must take two arguments: (key, value)*
  cacheFn: cache.set,
  // optionally logs out which action triggered
  // something to be cached and what reducers
  // were persisted as a result.
  logger: console.info,
  // We pass in the mapping of action types to
  // reducers that should be persisted
  actionMap,
});

const middlewares = [thunk, batch, persistMiddleware];

export {
  middlewares,
  reducer,
  cache,
  composeEnhancers
};

