import Api from 'api';
import { normalize } from 'normalizr';
import { qrCodes as qrCodesSchemes } from 'schemes';
import { fetchQRCode } from 'redux/actions';

export const fetchQrCodeByCode = (code) => async dispatch => {
  try {
    const { data } = await Api.getQrCodesAll(code);
    const {
      result,
      entities: { qrcodes },
    } = normalize(data, qrCodesSchemes);

    return dispatch(fetchQRCode(result, qrcodes));
  } catch (error) {
    console.error(error);
    throw error;
  }
};
